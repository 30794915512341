<template>
  <div
    class="notice"
    @click="openMessage"
    :title="length === 0 ? '暂无消息' : '点击查看详情'"
  >
    <img src="@/assets/message.png" alt />
    <div class="badge" v-if="length > 0">
      {{ length > 99 ? length + "+" : length }}
    </div>
  </div>
</template>

<script>
import { fetchList } from "@/api/message";
export default {
  data() {
    return {
      length: 0,
    };
  },

  mounted() {
    this.getList();

    this.timer = setInterval(() => {
      this.getList();
      console.log("refresh message");
    }, 1000 * 60);

    // this.$nextTick(() => {
    //   clearInterval(timer);
    // });
  },

  destroyed() {
    console.log("clear timer");
    clearInterval(this.timer);
  },

  methods: {
    openMessage() {
      this.$router.push("/message");
    },

    getList() {
      fetchList({
        pageNum: 1,
        pageSize: 100,
        isRead: 0,
      }).then((res) => {
        if (res && Array.isArray(res.list)) {
          this.length = res.list.length;

          this.getNotificationPermission()
            .then(() => {
              if (this.length > 0) {
                this.notificate(res.list[0]);
              }
            })
            .catch((err) => {
              this.$message.error(err);
            });
        }
      });
    },

    getNotificationPermission() {
      return new Promise((resolve, reject) => {
        if (!window.Notification) {
          reject("您的浏览器不支持发送通知，请更换浏览器！");
        } else {
          if (window.Notification.permission === "granted") {
            resolve();
          } else if (window.Notification.permission === "default") {
            window.Notification.requestPermission().then((permission) => {
              if (permission === "granted") {
                resolve();
              } else if (permission === "default") {
                reject("请点击允许按钮！");
              } else {
                reject(
                  "您已拒绝系统给您发送通知，如需此功能，请到浏览器设置页面进行设置！"
                );
              }
            });
          } else {
            reject(
              "您已拒绝系统给您发送通知，如需此功能，请到浏览器设置页面进行设置！"
            );
          }
        }
      });
    },

    notificate(info) {
      const title = info.title;
      const options = {
        body: info.content,
        icon: "https://portal.njszy.com/img/small-logo.0a905d4f.png",
      };
      const notification = new Notification(title, options);

      const that = this;
      notification.onclick = function () {
        that.$router.push("/message");
        // // 后期根据类型去修改
        // if (info.extra) {
        //   that.$router.push('/');
        // } else {
        //   that.$router.push("/message");
        // }
        notification.close();
      };
    },
  },
};
</script>

<style lang="less" scoped>
.notice {
  margin-left: auto;
  position: relative;
  cursor: pointer;
  img {
    width: 22px;
  }

  .badge {
    position: absolute;
    top: -4px;
    right: -4px;
    background-color: #0360fd;
    color: #fff;
    font-size: 12px;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>