
import request from '../request'

export function fetchList(params) {
    return request({
        url: '/message/list',
        params,
    })
}

export function read(params) {
    return request({
        url: '/message/read/' + params.id,
    })
}

export function readList(data) {
    return request({
        url: '/message/read',
        method: 'post',
        data,
    })
}
