<template>
  <div class="layout">
    <div class="header">
      <div class="logo" @click="$router.push('/vip')">
        <img src="@/assets/logo.png" />
      </div>

      <div class="welcome">
        <span style="color: #0562fd">{{ welcome }}</span>
        <span>{{ name }}</span>
        <span style="margin-left: 8px">👏</span>
      </div>

      <Notice />

      <img v-if="avatar" class="avatar" :src="avatar" alt @click="openMe" />
      <img
        v-else
        class="avatar"
        src="@/assets/avatar.png"
        alt
        @click="openMe"
      />

      <div class="user" @click="openMe">
        <div class="name">{{ name }}</div>
        <div class="position">{{ mobile }}</div>
      </div>

      <button class="logout" @click="logout">
        <span>退出登录</span>
      </button>
    </div>

    <div class="body">
      <div class="menu">
        <div
          class="item"
          v-for="item in menu"
          :key="item.title"
          @click="select(item)"
          :class="activePath.indexOf(item.path) === 0 ? 'active' : ''"
        >
          <img
            v-if="activePath.indexOf(item.path) === 0"
            :src="item.selectedIcon"
          />
          <img v-else :src="item.icon" />
          <span>{{ item.title }}</span>
        </div>
      </div>

      <div class="content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import Notice from "./components/notice.vue";
import { mapActions } from "vuex";
export default {
  components: {
    Notice,
  },

  data() {
    return {
      welcome: "欢迎您，",
      name: "",
      avatar: "",
      mobile: "",
      menu: [
        {
          icon: require("@/assets/icons/menu-project.png"),
          selectedIcon: require("@/assets/icons/menu-project-active.png"),
          title: "项目",
          path: "/project",
        },
        {
          icon: require("@/assets/icons/menu-task.png"),
          selectedIcon: require("@/assets/icons/menu-task-active.png"),
          title: "任务",
          path: "/task",
        },
        {
          icon: require("@/assets/icons/menu-book.png"),
          selectedIcon: require("@/assets/icons/menu-book-active.png"),
          title: "计算书",
          path: "/book",
        },
      ],
      activePath: this.$route.path,
    };
  },

  watch: {
    "$route.path"() {
      console.log("path changed");
      this.activePath = this.$route.path;
    },
  },

  mounted() {
    this.name = window.localStorage.getItem("name") || "";
    this.avatar = window.localStorage.getItem("avatar") || "";
    this.mobile = window.localStorage.getItem("mobile") || "";

    this.getDataDict();

    if(localStorage.getItem("mobile") == "18066102324") {
      this.menu.push({
        icon: require("@/assets/icons/menu-book.png"),
        selectedIcon: require("@/assets/icons/menu-book-active.png"),
        title: "权限管理",
        path: "/system",
      })
      this.menu.push({
        icon: require("@/assets/icons/menu-book.png"),
        selectedIcon: require("@/assets/icons/menu-book-active.png"),
        title: "用户管理",
        path: "/user",
      })
      this.menu.push({
        icon: require("@/assets/icons/menu-book.png"),
        selectedIcon: require("@/assets/icons/menu-book-active.png"),
        title: "审批管理",
        path: "/approve",
      })
    }
  },

  methods: {
    ...mapActions("setting", ["getDataDict"]),
    select(item) {
      this.$router.push(item.path);
    },

    openMe() {
      this.$router.push("/me");
    },
    logout() {
      this.$router.push("/login");
    },
  },
};
</script>
<style lang="less" scoped>
.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    134deg,
    #ffffff 0%,
    #f3f7fb 20%,
    #e9f0fb 41%,
    #f0f5fd 60%,
    #fafcff 80%,
    #e8eaf6 100%
  );
}

.header {
  display: flex;
  align-items: center;
  padding: 40px;
  // position: sticky;
  // top: 0;
  // left: 0;
  // right: 0;

  .logo {
    cursor: pointer;

    img {
      height: 40px;
      display: block;
    }
  }

  .welcome {
    font-family: title;
    padding-left: 24px;
    font-size: 24px;
    color: #323232;
  }

  .avatar {
    cursor: pointer;
    width: 40x;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
    margin-left: 32px;
    margin-right: 8px;
  }

  .user {
    cursor: pointer;
    .name {
      font-weight: 500;
    }
    .position {
      color: #a7a8aa;
      font-size: 12px;
    }
  }

  .logout {
    margin-left: 32px;
    background: linear-gradient(
      180deg,
      rgba(7, 153, 255, 1),
      rgba(3, 96, 253, 1)
    );
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px;
    box-shadow: 0px 2px 4px 0px rgba(3, 96, 253, 0.19);
    width: 102px;

    span {
      line-height: 28px;
      height: 28px;
      border-radius: 8px;
      flex: 1;
      background-color: #fff;
      color: #0798ff;
    }
  }
}

.body {
  flex: 1;
  display: flex;
  padding: 0 40px 40px;
}

.menu {
  width: 234px;

  .item {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 56px;
    padding: 0 28px;
    border-radius: 15px;

    img {
      height: 20px;
      display: block;
      margin-right: 16px;
    }
    span {
      line-height: 1em;
      font-size: 18px;
      color: #5d677b;
    }
  }

  .active {
    background: linear-gradient(143deg, #0c99ff 0%, #0560fd 100%);
    box-shadow: 0px 5px 8px 0px rgba(5, 96, 253, 0.32);

    span {
      color: #fff;
    }
  }
}

.content {
  flex: 1;
  padding-left: 40px;
  display: flex;
  flex-direction: column;
}
</style>